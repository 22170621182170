import Axios from 'axios';

export interface RequestInterface {
  setParameters: (parameters) => this
  setEndPoint: (endpoint) => this
  setParameter: (parameter, value) => this
  removeParameters: (params) => this
  removeParameter: (param) => this
  get: (data) => Promise<any>
  post: (data) => Promise<any>
  put: (data) => Promise<any>
}

export default class Request implements RequestInterface {
  private endpoint;

  private readonly parameters;

  constructor(endpoint, parameters = {}) {
    this.endpoint = endpoint;
    this.parameters = parameters;
  }

  public setEndPoint = (endpoint: string) => {
    this.endpoint = endpoint;
    return this;
  };

  public setParameters = (parameters: {}) => {
    Object.keys(parameters).forEach(key => {
      this.parameters[key] = parameters[key];
    });

    return this;
  };

  public setParameter = (parameter: string, value: string|number|{}) => {
    this.parameters[parameter] = value;

    return this;
  };

  public removeParameters = (parameters: string[]) => {
    parameters.forEach(parameter => {
      delete this.parameters[parameter];
    });

    return this;
  };

  public removeParameter = (parameter: string) => {
    delete this.parameters[parameter];

    return this;
  };

  private getParameterString = (): string => {
    const keys = Object.keys(this.parameters);

    const parameterStrings = keys
      .filter(key => !!this.parameters[key])
      .map(key => `${key}=${this.parameters[key]}`);

    return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`;
  };

  public submit = (requestType, url, data = null) => (new Promise((resolve, reject) => {
    Axios[requestType](url + this.getParameterString(), data)
      .then(response => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        if (response) {
          reject(response.data);
        } else {
          reject();
        }
      });
  }));

  public get = (data = null) => (this.submit('get', this.endpoint, data));

  public post = (data = null) => (this.submit('post', this.endpoint, data));

  public put = (data = null) => (this.submit('put', this.endpoint, data));
}
