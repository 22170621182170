import Ayeps from './Ayeps';
import Modal from './plugin/modal';
import Request from './plugin/request';

const app = new Ayeps();

app.register('modal', Modal);
app.register('request', Request, '/api');

app.init();
