const deliveryForm = <HTMLFormElement> document.getElementById('delivery-form');
const mkad = <HTMLInputElement> deliveryForm.querySelector('[name="mkad"]');
const hoursInput = <HTMLInputElement> deliveryForm.querySelector('[name="hours"]');
const weight = <HTMLSelectElement> deliveryForm.querySelector('[name="weight"]');
const { pricelist } = (<any> window);
const priceBox = <HTMLElement> document.querySelector('.js-calc-price');

const thousandSep = (price, sep = ' ') => (price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep));

const calulate = () => {
  if (weight.value === '' || !pricelist[parseInt(weight.value, 10)]) {
    return;
  }
  const item = pricelist[parseInt(weight.value, 10)];
  const hours = parseInt(hoursInput.value, 10) < item.min ? item.min : parseInt(hoursInput.value, 10);

  priceBox.innerHTML = thousandSep(parseInt(mkad.value, 10) * item.mkad + hours * item.price);
};

mkad.onchange = calulate;
mkad.onkeyup = calulate;

weight.onchange = () => {
  const item = pricelist[parseInt(weight.value, 10)];
  if (parseInt(hoursInput.value, 10) < item.min) {
    hoursInput.value = item.min;
  }
  calulate();
};

hoursInput.onchange = calulate;
hoursInput.onkeyup = calulate;
