interface AyepsInterface {
  [key: string]: any
}
declare global {
  interface Window {
    Ayeps: AyepsInterface
  }
}
export default class Ayeps implements AyepsInterface {
  readonly modules: {};

  constructor() {
    this.modules = {};
  }

  /**
   * Register module
   * @param name
   * @param Module
   * @param args
   */
  public register = (name, Module, ...args) => {
    if (this.modules[name]) {
      return;
    }
    this.modules[name] = new Module(args);
  };

  /**
   * Init App after registering all modules
   */
  public init = () => {
    if (window.Ayeps) {
      return;
    }
    Object.keys(this.modules).forEach(key => {
      Object.defineProperty(this, key, {
        get: () => (this.modules[key]),
      });
    });

    window.Ayeps = this;
  };
}
