$('.js-button-frame').on('click', () => {
  $('html').toggleClass('menu-open');
});

$('a[href^="#"]').bind('click.smoothscroll', function(e) {
  e.preventDefault();
  const target = this.hash; const
    $target = $(target);
  $('html, body').stop().animate({
    scrollTop: $target.offset().top,
  }, 900, 'swing', () => {
    window.location.hash = target;
  });
});
