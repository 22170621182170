import { forEachElement } from '../utils/elements';
import serialize from 'form-serialize';
import { RequestInterface } from '../plugin/request';
import Modal from '../component/modal';

const { request, modal }: { request?: RequestInterface; modal?: Modal} = window.Ayeps;
const notification = document.querySelector('#modal-notification .js-message');
const deliveryForm = <HTMLFormElement> document.getElementById('delivery-form');

const getErrorNode = (error: string): HTMLElement => {
  const el = document.createElement('p');
  el.classList.add('form-error');
  el.innerHTML = error;
  return el;
};

const handleSuccess = response => {
  if (Object.prototype.hasOwnProperty.call(response, 'message')) {
    const { message = '' } = response;
    notification.innerHTML = message;

    modal.open('modal-notification');
  }
};

const handleError = (form: HTMLFormElement, response) => {
  const { errors } = response;
  Object.keys(errors).forEach(key => {
    const input = form.querySelector(`[name="${key}"]`);
    const parent = input.closest('div');
    input.classList.add('has-error');
    parent.appendChild(getErrorNode(errors[key]));
  });
};

const handleResponse = (form: HTMLFormElement, response) => {
  if (response.success) {
    handleSuccess(response);
    return;
  }
  handleError(form, response);
};

const removeErrors = (form: HTMLFormElement) => {
  forEachElement(form.querySelectorAll('.form-error'), (el: HTMLElement) => {
    el.remove();
  });
};

forEachElement('.js-form', (form: HTMLFormElement) => {
  form.onsubmit = (e: Event) => {
    e.preventDefault();
    const data = serialize(form);
    removeErrors(form);
    request.setEndPoint(form.getAttribute('action'));
    request.post(data)
      .then(response => {
        if (!Object.hasOwnProperty.call(response, 'success')) {
          throw new Error('Response "success" property is missing');
        }
        handleResponse(form, response);
      })
      .catch(err => {
        if (err.errors) {
          handleError(form, err);
        }
      });
  };
});

forEachElement('.js-calculator-order', (form: HTMLFormElement) => {
  form.onsubmit = (e: Event) => {
    e.preventDefault();
    const data = serialize(form);
    removeErrors(form);
    request.setEndPoint(form.getAttribute('action'));
    request.post(`${data}&${serialize(deliveryForm)}`)
      .then(response => {
        if (!Object.hasOwnProperty.call(response, 'success')) {
          throw new Error('Response "success" property is missing');
        }
        handleResponse(form, response);
      })
      .catch(err => {
        if (err.errors) {
          handleError(form, err);
        }
      });
  };
});
