import 'swiper/dist/css/swiper.min.css';
import {
  Swiper,
  Navigation,
  Pagination,
} from 'swiper/dist/js/swiper.esm.js';
import { forEachElement } from '../utils/elements';

Swiper.use([Navigation, Pagination]);

interface ISlider extends HTMLElement {
  slider: Swiper
}
interface ISliderBtn extends HTMLElement {
  slider: Swiper
}
const initSlider = (el: ISlider, args) => {
  const options = Object.assign({
    slidesPerView: 1,
    breakpointsInverse: true,
    spaceBetween: el.dataset.spacebetween ? parseInt(el.dataset.spacebetween, 10) : 0,
    pagination: {
      el: el.querySelector('.js-pagination') ? el.querySelector('.js-pagination') : null,
      // type: 'fraction',
    },
    navigation: {
      nextEl: el.querySelector('.js-next') ? el.querySelector('.js-next') : null,
      prevEl: el.querySelector('.js-prev') ? el.querySelector('.js-prev') : null,
    },
  }, args);

  if (el.dataset.pagination) {
    options.pagination.el = document.querySelector(el.dataset.pagination);
  }

  if (el.dataset.next) {
    // @ts-ignore
    options.navigation.nextEl = el.dataset.next;
  }

  if (el.dataset.prev) {
    // @ts-ignore
    options.navigation.prevEl = el.dataset.prev;
  }

  el.slider = new Swiper(el, options);
};

forEachElement('.js-carousel-4', (el: ISlider) => {
  const args = {
    breakpoints: {
      992: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      576: {
        slidesPerView: 2,
      },
    },
  };

  initSlider(el, args);
});

forEachElement('.js-select-slider', (el: ISlider) => {
  const args = {
    autoHeight: true,
    breakpoints: {
      1170: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 1,
      },
    },
  };
  initSlider(el, args);
});


forEachElement('.js-client-slider', (el: ISlider) => {
  const args = {
    loop: true,
    breakpoints: {
      1170: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 1,
      },
    },
  };
  initSlider(el, args);
});


forEachElement('.js-rewievs-slider', (el: ISlider) => {
  initSlider(el, {
    autoHeight: true,
  });
});

forEachElement('.js-target-next', (el: ISliderBtn) => {
  if (!el.dataset.target) {
    return;
  }
  const slider = <ISlider> document.querySelector(el.dataset.target);
  el.slider = slider.slider;
  el.onclick = (e: Event) => {
    e.preventDefault();
    el.slider.slideNext();
  };
});

forEachElement('.js-target-prev', (el: ISliderBtn) => {
  if (!el.dataset.target) {
    return;
  }
  const slider = <ISlider> document.querySelector(el.dataset.target);
  el.slider = slider.slider;
  el.onclick = (e: Event) => {
    e.preventDefault();
    el.slider.slidePrev();
  };
});

forEachElement('.gallery-top', (el: ISlider) => {
  const thumbEl = <ISlider> el.nextElementSibling;
  el.slider = new Swiper(el, {
    spaceBetween: 10,
    navigation: {
      nextEl: '.js-card-next',
      prevEl: '.js-card-prev',
    },
    thumbs: {
      swiper: {
        el: thumbEl,
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.js-cardthumbs-next',
          prevEl: '.js-cardthumbs-prev',
        },
      },
    },
  });
});
